import { PageOptions } from '@graphcommerce/framer-next-pages'
import { hygraphPageContent, HygraphPagesQuery } from '@graphcommerce/graphcms-ui'
import { ProductListDocument, ProductListQuery } from '@graphcommerce/magento-product'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { GetStaticProps, JsonLd, MetaRobots, PageMeta } from '@graphcommerce/next-ui'
import { LayoutNavigation, LayoutNavigationProps, RowProduct, RowRenderer } from '../components'
import { LayoutDocument } from '../components/Layout/Layout.gql'
import { useResetCheckout } from '../hooks/useResetCheckout'
import { graphqlSharedClient, graphqlSsrClient } from '../lib/graphql/graphqlSsrClient'
import appLogo from '../public/templi-logo.svg'

type Props = HygraphPagesQuery & ProductListQuery
type RouteProps = { url: string }
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props, RouteProps>

function CmsPage(props: Props) {
  const { pages, products } = props

  useResetCheckout()
  const page = pages?.[0]
  const product = products?.items?.[0]
  const localPath = appLogo?.src?.replace('/', '')
  return (
    <>
      <PageMeta
        title={page?.metaTitle ?? page?.title ?? ''}
        metaDescription={page?.metaDescription ?? ''}
        metaRobots={page?.metaRobots.toLowerCase().split('_') as MetaRobots[] | undefined}
        canonical='/'
      />

      {page && (
        <>
          <JsonLd
            item={{
              '@context': 'https://schema.org',
              '@type': 'Organization',
              image: `${process.env.NEXT_PUBLIC_CANONICAL_BASE_URL}${localPath}`,
              url: 'https://www.templi.com/',
              logo: `${process.env.NEXT_PUBLIC_CANONICAL_BASE_URL}${localPath}`,
              name: 'Templi',
              description: 'A Marketplace for Ordering Printed Goods and Supplies.',
              email: 'contact@templi.com',
              telephone: '+1-201-837-6000',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '1000 Dean Street',
                addressLocality: 'New York',
                addressCountry: 'US',
                addressRegion: 'New York',
                postalCode: '11238',
              },
              contactPoint: {
                '@type': 'ContactPoint',
                telephone: '+1-201-837-6000',
                email: 'contact@templi.com',
              },
            }}
          />
          <RowRenderer
            content={page.content}
            renderer={{
              RowProduct: (rowProps) => (
                <RowProduct {...rowProps} {...product} items={products?.items} />
              ),
            }}
          />
        </>
      )}
    </>
  )
}

CmsPage.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions

export default CmsPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)

  const conf = client.query({ query: StoreConfigDocument })
  const page = hygraphPageContent(staticClient, 'page/home')
  const layout = staticClient.query({ query: LayoutDocument, fetchPolicy: 'cache-first' })

  // todo(paales): Remove when https://github.com/Urigo/graphql-mesh/issues/1257 is resolved
  const favoritesList = staticClient.query({
    query: ProductListDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'MTIx' } } },
  })

  const latestList = staticClient.query({
    query: ProductListDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'MTAy' } } },
  })

  const swipableList = staticClient.query({
    query: ProductListDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'MTIy' } } },
  })

  if (!(await page).data.pages?.[0]) return { notFound: true }

  return {
    props: {
      ...(await page).data,
      ...(await layout).data,
      latestList: (await latestList).data,
      favoritesList: (await favoritesList).data,
      swipableList: (await swipableList).data,
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 5,
  }
}
